import React, { useState } from 'react';

const CreateNewUser = ({fire, currentProject, ...props}) => {
    const [newUser, updateNewUser] = useState({ name: false, email: false, pwd: false });

    function createNewUser(e) {
        e.preventDefault();
        if (!newUser.name || !newUser.email || !newUser.pwd) {
            return false;
        }
        // fire.functions().useEmulator("localhost", 5001);
        const createUser = fire.functions().httpsCallable('createUser');

        createUser(newUser)
            .then((result) => {
                const uid = result.data;
                const permissions = currentProject ? {[currentProject]: true} : false;
                const newUserData = {
                    'email': newUser.email,
                    'userName': newUser.name,
                    'role': 'editor',
                    'permissions': permissions
                }
                fire.database().ref().child('users').child(uid).set(newUserData);
            })
            .then(() => {
                updateNewUser({ name: false, email: false, pwd: false });
            })
            .catch(err => console.error(err));
    }

    function handleUserInput(e) {
        const _type = e.target.id.split('new-user-')[1];
        let prevStateUsr = { ...newUser };
        prevStateUsr[_type] = e.target.value;
        updateNewUser(prevStateUsr)
    }

    return (
        <div className = 'content wrapper max--400' >
            <h3>Skapa en ny användare</h3>
            {props.infoText &&
            <p>{props.infoText}</p>
            }

            <form className="login-wrapper flex-col box" onSubmit={createNewUser}>

                <input required id="new-user-name" value={newUser.name || ''} type="text" onChange={handleUserInput} placeholder="Namn" />
                <input required id="new-user-email" value={newUser.email || ''} type="email" onChange={handleUserInput} placeholder="Email" />
                <input required id="new-user-pwd" value={newUser.pwd || ''} type="password" onChange={handleUserInput} placeholder="password" />
                <input type="submit" value="Skapa konto" />

                <span id="error-message"></span>

            </form>
        </div >
    );
};

export default CreateNewUser;