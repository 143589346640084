import React from 'react';
import { useEffect } from 'react';
import './List.scss';
import { getStatusClass } from '../../../functions/methods';

const List = ({options, loading, items, sortedList, project, updateOptions,  updateSortValue, activeImg, viewNextImage, ...props}) => {
    // console.log('this is the sorted list:', sortedList);
    // console.log('this items:', items);
    useEffect(()=>{
        // updateSortValue('title');
        // console.log('L I S T', items);

    },[])

    function toggleList(){
        updateOptions('list','visible',!options.list.visible)
    }

    function toggleFilter(){
        updateOptions('filter','visible',!options.filter.visible)
    }

    async function handleOptionChange(e){
        let val = e.currentTarget.value;
        updateOptions('list', 'active', true)
        // console.log('update ', val);
        updateSortValue(val);

        // await this.sortData(this.props.data.items, val).then((sortedList) => {
        //     this.filterItems(this.state.filterValues, sortedList);
        //     // console.log('sortedList', sortedList);
        // });
    }

    async function showArea(e){
        let _target = e.currentTarget;
        let _id = _target.getAttribute('objectid');
        let _svg = document.getElementsByClassName('hotspots')[0];
        let _area = document.getElementById('area_' + _id);

        if (_target.classList.value && _target.classList.value.match('different-view')) {
            await viewNextImage();
        }

        let count = 0;
        const checkForArea = setInterval(function () {
            count++;
            _area = document.getElementById('area_' + _id);

            if (_area && !loading) {

                clearInterval(checkForArea);

                toggleList();
                _svg.classList.add('highlight-mode');
                _area.classList.add('highlight');

                setTimeout(function () {
                    _area.classList.remove('highlight');
                    _svg.classList.remove('highlight-mode');

                }, 3500);

            }
            else if (count > 10) {
                clearInterval(checkForArea);
            }
        }, 300)
    }

    return (
        
        <div className={(options.list.visible) ? 'list-wrapper modal show' : 'modal list-wrapper'}>
            <div className="icon icon-close" onClick={toggleList}></div>

            <div className="container">
                <div className="container__header">
                    <h3>{project.title}</h3>


                    <div className={options.filter.active ? "filter-info" : "hidden"}>
                        <p>Filtret är aktivt så listan visar enbart objekt som matchar din filtrering.</p>
                        <div className="flex-row">
                            <div onClick={toggleFilter} className="btn--mini">Visa filter</div>
                            <div onClick={props.clearFilter} className="btn--mini">Rensa filter</div>
                        </div>
                    </div>

                    <div className="select-wrapper select-wrapper--small sort-options">
                        <select onChange={handleOptionChange}>
                            <option value="title">Namn</option>
                            <option value="price">Pris</option>
                            <option value="fee">Avgift</option>
                            <option value="rooms">Rum</option>
                            <option value="living_space">Boarea</option>
                            <option value="yard_area">Tomtarea</option>
                            <option value="status">Status</option>
                        </select>
                    </div>

                </div>
                <ul className="list--tight">
                    {
                        Object.keys(items).length > 0 &&
                        sortedList.map((key) => {

                            if(!items[key])return null;
                            let _points = items[key].points ? items[key].points[activeImg] : null;
                            const status = getStatusClass(items[key].status);
                            let _className = 'list__object';
                            if (!items[key].points) {
                                _className += ' hidden';
                            }
                            else if (items[key].points && !_points) {
                                _className += ' different-view';

                            }
                            return (
                                <li key={key}
                                    onClick={showArea}
                                    className={_className}
                                    objectid={key}>
                                    <div className={"status-dot " + status}></div>

                                    <span className="list__object__title">{items[key].title}</span>


                                    <div className="list__object__info">
                                        <span>
                                            <b>{items[key].info.price.title}</b> : {items[key].info.price.text}
                                        </span>
                                        <span>
                                            <b>{items[key].info.fee.title}</b> : {items[key].info.fee.text}
                                        </span>
                                        <span>
                                            <b>{items[key].info.rooms.title}</b> : {items[key].info.rooms.text}
                                        </span>
                                        <span>
                                            <b>{items[key].info.living_space.title}</b> : {items[key].info.living_space.text}
                                        </span>
                                        <span>
                                            <b>{items[key].info.yard_area.title}</b> : {items[key].info.yard_area.text}
                                        </span>

                                    </div>
                                    <span className="list__object__link">Visa</span>
                                </li>
                            );
                        })
                    }
                </ul>


                <div className="flex-row list-availability">
                    <div className="flex-row"><div className="status-dot available"></div>Tillgängliga</div>
                    <div className="flex-row"><div className="status-dot reserved"></div>Reserverade</div>
                    <div className="flex-row"><div className="status-dot sold"></div>Sålda</div>
                </div>


            </div>

        </div>
    );
};

export default List;