import React from 'react';
import View from '../Default';
import Login from '../../components/Login/Login';
import { useState } from 'react';
import { getExternalData, getUser } from '../../functions/Queries';
import ItemController from '../../components/ItemController/ItemController';
import './Admin.scss';
import Input from '../../components/Input/Input';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import firebase from '../../config/firebase';
import Button from '../../components/Button/Button';
import IconButton from '../../components/Button/IconButton';
import { getInitialItemData } from '../../config/initialData';
import CreateNewUser from '../../components/CreateNewUser/CreateNewUser';

const Admin = ({auth, ...props}) => {
    const [user, updateUser] = useState(false);
    const [uploadingImage, setUploadingStatus] = useState(false);
    const [activeObjectID, setActiveObjectID] = useState(false);
    const [sortOnClicks, setSortOnClicks] = useState(false);
    const [showItemView, toggleItemView] = useState(false);
    const [projectDefaultEmail, setProjectDefaultEmail] = useState(props.data.project.email || '');
    const [generatedCode, setGeneratedCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [fetching, setFetchLoader] = useState(false);
    const [createNewUsr, setCreateNewUsr] = useState(false);
    const ref = firebase.database().ref();
    const projectRef = ref.child('projects').child(props.projectID);


    function handleLogin(usr){
        getUser(usr.uid, updateUser);
        props.onLogin();

    }

    function handleLogout(usr){
       console.log('log out');
       updateUser(false);
    }

    function handleReverseButtons(){
        projectRef.child('project').child('reverseButtons').set(!props.data.project.reverseButtons);
    }

    function setProjectAsExternal(){
        projectRef.child('project').child('externalData').set(!props.data.project.externalData);
    }

    const getObjectId = (currentTarget) => {
        let objectId = currentTarget.getAttribute('objectid');

        do {
            currentTarget = currentTarget.parentNode;
            objectId = currentTarget.getAttribute('objectid');
        }
        while ((objectId === undefined || objectId === null));

        return objectId;
    }

    function uploadFile(id, file){
        let uniqueId = id.replace('project-image-', '');
        var imgObj = {
            id: id,
            file: file
        }

        uploadImage(imgObj, uniqueId);
    }

    function uploadImage(obj, chosenImgId){

        var file = obj.file;

        // Create a root reference
        var storageRef = firebase.storage().ref();

        var _name = file.name.replace(' ', '-');
        // Create the file metadata
        var metadata = {
            contentType: 'image/jpeg'
        };


        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child('images/' + _name).put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed', function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadingStatus(true);

            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running ' + progress);
                    break;
                default:
                    break;
            }
        }, function (error) {

            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    break;


                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                default:
                    break;
            }
        }, function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);

                projectRef.child('project').child('images').child(chosenImgId).set(downloadURL);
                setUploadingStatus(false);
                props.updateActiveImg(chosenImgId);
            });
        });

    }

    function saveProjectEmail(){
        projectRef.child('project').child('email').set(projectDefaultEmail);

    }

    function handleProjectEmail(prop, val){
        setProjectDefaultEmail(val)
    }

    function handleListView(){
        const listVal = props.data.project.list ? !props.data.project.list : true;
        projectRef.child('project').child('list').set(listVal);
    }

    function handleInterest(){
        const interestVal = props.data.project.interest ? !props.data.project.interest : true;
        projectRef.child('project').child('interest').set(interestVal);
    }

    function removeImage(imgID){
        projectRef.child('project').child('images').child(imgID).remove();
        props.updateActiveImg('image1');
    }

    function addImage(){
        const imgID = `image${Object.keys(props.data.project.images).length + 1}`;
        projectRef.child('project').child('images').child(imgID).set("");
        props.updateActiveImg(imgID);
    }

    function handleDeleteClick(e){
        let objectID = getObjectId(e.target);
        projectRef.child('items').child(objectID).remove();

    }
    function handleDuplicateClick(e){
        const duplicateID = getObjectId(e.target);
        const duplicateItem = props.data.items[duplicateID];
        let initialItemData = getInitialItemData();
        initialItemData.title = duplicateItem.title;
        initialItemData.text = duplicateItem.text;
        initialItemData.info = duplicateItem.info;
        initialItemData.status = duplicateItem.status;
        initialItemData.email = duplicateItem.email;
        initialItemData.link = duplicateItem.link;


        projectRef.child('items').child(initialItemData.id).set(initialItemData);
    }

    

    function handleEditClick(e){
        let currentTarget = e.target;
        let objectId = getObjectId(currentTarget);
        setActiveObjectID(objectId);
        toggleItemView(true);
    }

    function createNewObject(){
        let initialItemData = getInitialItemData();
        projectRef.child('items').child(initialItemData.id).set(initialItemData);
        toggleItemView(false);

    }

    function fetchData(){

        setFetchLoader(true);
        // firebase.functions().useEmulator("localhost", 5001);

        const getItemsById = firebase.functions().httpsCallable('getReblaProjectItems');

        getItemsById(props.projectID)
            .then((response) => {
                const items = response.data;
                console.log('result: ', items);
                setFetchLoader(false);
                if(!items){
                    setErrorMessage("Det fanns inget projekt med detta ID.")
                }
                else {
                    projectRef.child('items').set(items);
                    setProjectAsExternal();
                }
            })
            .catch((err)=>{
                console.error('error: ', err);
                setFetchLoader(false);

                setErrorMessage("Hämtningen gick fel... Servern kanske ligger nere eller så kanske du använder ett ID som existerar i det externa api:et")

                setTimeout(function(){
                    setErrorMessage(false);
                },5000)
            }) 
    }


    const getItems = () =>{
        if(!props.data.items){ 
            return (
                <div className="flex-col wrapper">
                    Det finns inga objekt i detta projekt ännu. 
                </div>
            )
        }
        else {
            const objects = props.data.items;

            return (
                <ul className={sortOnClicks ? "list cols-2 order" : "list cols-2"}>
                {
                Object.keys(objects).map((key) => {
                    let objectPoints = (objects[key].points) ? objects[key].points[props.activeImg] : null;
                    let count = objects[key].users ? Object.keys(objects[key].users).length : 0;
                    let styles = {
                        "order": count
                    }
                    return (
                        <li style={sortOnClicks ? styles : {}} key={'object-key-' + key} className={(objectPoints) ? 'list__object' : 'list__object missing-area'} objectid={key}>
                            <span className="list__object__title">{objects[key].title}
                                <span className="list__object__count">{count + " klick"}</span>

                            </span>

                            <div className="list__object__menu">
                                {((props.data.project.externalData && user.role === 'admin') || user.role === 'admin') && 
                                <>
                                <div className="list__object__btn--hover btn-delete" onClick={handleDeleteClick}><i className="fas fa-trash-alt"></i></div>
                                <div className="list__object__btn--hover btn-duplicate" onClick={handleDuplicateClick}><i className="fas fa-clone"></i></div>
                                </>
                                }
                                <div className="list__object__btn" onClick={handleEditClick}><i className="fas fa-pencil-alt"></i></div>
                            </div>
                            <div className="not-visible fas fa-eye-slash"></div>
                        </li>
                    );
                })
                }
                </ul>
            );
        }

    }

    const getAdminButtons= ()=>{
        if (user) {
            console.log('props.data.project.externalData', props.data.project.externalData);
            return (
                <div className="flex-row admin-buttons">
                    {!props.data.items && 
                    <div className={`fetch-button${fetching ? ' loading' : ''}`}>
                        <Button onClick={fetchData} text="Hämta extern data" />
                        {errorMessage &&
                        <div className="error-message">
                            {errorMessage}
                        </div>
                        }
                    </div>
                    }
                    {((props.data.project.externalData && user.role === 'admin') || user.role === 'admin' || (props.data.project.externalData === undefined) ) &&
                    <div className="">
                        <Button onClick={createNewObject} text="Skapa nytt objekt" />
                    </div>
                    }
                    <div className={(!showItemView) ? "hidden" : ""}>
                        <Button onClick={()=>toggleItemView(!showItemView)} text="Ange ytor" />
                    </div>
                </div>
            )
        }
        return false;
    }   

    const hostName = window.location.origin;

    function generateCode(){
        const sizes = props.imageSize || {height:"2625", width: "3500"};
        const projectCode = `<div class="bv-iframe-container">
                                <link rel='stylesheet' href='${hostName}/assets/iframe-styles.css' type='text/css' media='all' />
                                <iframe src="${hostName}?id=${props.projectID}${props.data.project.layout ? '&layout=' + props.data.project.layout : ''}" frameborder="0"></iframe>
                                <style>.bv-iframe-container:before{padding-top: calc( ( ${sizes.height} / ${sizes.width} ) * 100%);}</style>
                            </div>`;
        setGeneratedCode(projectCode);
        navigator.clipboard.writeText(projectCode);

        setTimeout(function(){
            setGeneratedCode(false);

        }, 5000);

    }

    function handleLayoutChange(e){
        const val = e.target.value !== 'false' ? e.target.value : false;
        projectRef.child('project').child('layout').set(val);
    }

    if(user && (!(user.role === "admin") && !user.permissions[props.projectID] )){
        return (
            <div>Du har inte behörighet att se denna sida.</div>
        )
    }

    return (
        <div className="admin">
            <div className="admin container">
            {user ?
                <>
                <Login user={user} onLogin={handleLogin} onLogout={handleLogout} />
                
                <div className="content">
                    <figure className="banner-image">
                                <img src={props.data.project.images[props.activeImg] || "https://via.placeholder.com/1400x500"} alt="banner" />
                    </figure>

                    <div className="objects">
                        <header className="container__header">

                            <div className="image-uploaders">
                                {
                                    Object.keys(props.data.project.images).map((img, index) => (
                                        <ImageUploader id={"project-image-" + img} removeImage={removeImage} mainImage={index===0} key={"project-image-" + img} className={(img === props.activeImg) ? 'highlight' : ''} imgSrc={props.data.project.images[img]} onUpload={uploadFile} label="Projektbild" />
                                    ))
                                }
                                {uploadingImage &&
                                    <div className="image-uploading small-loader">Laddar upp bild ... </div>
                                }

                                {Object.keys(props.data.project.images).length < 3 &&
                                    <div className="image-uploaders__add" onClick={addImage}>Lägg till vy</div>
                                }
                            </div>

                            <h2>{props.data.project.title}</h2>
                            <p>Här administreras varje bostad och dess yta på bilden. När symbolen <i className="inline-icon fas fa-eye-slash"></i> visas så innebär det att det inte kopplats till någon yta ännu. Varje objekt måste kopplas till den vy som den ska synas i och det är också möjligt att visa ett och samma objekt i olika vyer.</p>
                                    
                            <div className='flex-col input-wrapper main-settings'>
                                <h3>Huvudinställningar</h3>
                                {props.data.project.layout || user.role === "admin" && 
                                <div className="project-layout">

                                    <div className="select-wrapper" label="Utseende">
                                        <select name="" id="" onChange={handleLayoutChange} defaultValue={props.data.project.layout || false}>
                                            <option value={false}>Normalt</option>
                                            <option value="boklok">BoKlok</option>
                                        </select>
                                    </div>
                                </div>
                                }

                                <div className="checkboxes flex-row-start">
                                    <div className="flex-row checkbox-wrapper">
                                        <label >
                                            <input
                                                onChange={handleReverseButtons}
                                                type="checkbox"
                                                checked={props.data.project.reverseButtons}
                                                name="button-position" />
                                            Byt plats på knapparna</label>

                                    </div>
                                    <div className="flex-row checkbox-wrapper">
                                        <label >
                                            <input
                                                onChange={handleListView}
                                                type="checkbox"
                                                checked={props.data.project.list} />
                                            Visa listvy</label>
                                    </div>
                                    <div className="flex-row checkbox-wrapper">
                                        <label >
                                            <input
                                                onChange={handleInterest}
                                                type="checkbox"
                                                checked={props.data.project.interest} />
                                            Visa intresseanmälan</label>
                                    </div>
                                </div>
                                
                                {props.data.project.interest &&
                                    <>
                                    {
                                        props.data.project.externalData &&
                                        <>
                                            <span className='info-text'>
                                            Formuläret kopplas mot externt API
                                            </span>
                                            <br/>
                                        </>

                                    }
                                    <Input id="realtor-email" large value={projectDefaultEmail} type="email" onChange={handleProjectEmail} placeholder="Ange e-post" label={` ${props.data.project.externalData ? 'Ange en e-post som backup' : 'Ansvarig mäklares e-post'}`} onSave={saveProjectEmail} />

                                    </>  
                                }

                            </div>
                            {createNewUsr &&
                                <CreateNewUser fire={firebase} currentProject={props.projectID} />
                            }

                            <div className="display-settings">

                                {user.role === 'admin' &&
                                <IconButton icon="user" onClick={() => setCreateNewUsr(!createNewUsr)}>Skapa ny användare</IconButton>
                                }

                                <IconButton href={`/?id=${props.projectID}${props.data.project.layout ? '&layout=' + props.data.project.layout : ''}`} icon="link">Gå till projektet</IconButton>
                                <IconButton onClick={generateCode} icon="code">Generera inbäddningskod</IconButton>
                                <div className={`code-viewer${generatedCode ? '--show' : ''}`}>
                                    <p>Denna kod är redan kopierad i din clipboard: </p>
                                    <code>{generatedCode}</code>
                                </div>
                            </div>

                            

                        </header>

                        <h2>Bostäder</h2>
                        
                        {props.data.items &&
                            <div className="btn--small btn--invert" onClick={(e) => setSortOnClicks(!sortOnClicks) }>
                                <span className={sortOnClicks ? "hidden" : ""}>Sotera på klick</span>
                                <span className={sortOnClicks ? "" : "hidden"}>Normal sortering</span>
                            </div>
                        }
                            {
                                getItems()
                            }

                        <div className="">

                            {showItemView &&
                                <ItemController key={activeObjectID} data={props.data} user={user} projectRef={projectRef} projectID={props.projectID} onSave={()=>toggleItemView(false)} saveObject={''} objectID={activeObjectID} />
                            }

                            {getAdminButtons()}

                        </div>

                    </div>

                </div>

                {props.children}

                    
                </>
            :
                <Login user={user} onLogin={handleLogin} onLogout={handleLogout}/>
            }
            </div>
        </div>
    )

}

export default Admin;