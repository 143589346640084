import React, { useState } from 'react';
import { useEffect } from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import Login from '../../components/Login/Login';
import { getAllProjects, getAllusers, getUser } from '../../functions/Queries';
import fire from '../../config/firebase';
import Input from '../../components/Input/Input';
import CreateNewUser from '../../components/CreateNewUser/CreateNewUser';
import { PROJECT_ID_START_STRING } from '../../functions/methods';
// fire.functions().useEmulator("localhost", 5001);

const SuperAdmin = () => {
    const [user, updateUser] = useState(false);
    const [users, updateUsers] = useState(false);
    const [projects, updateProjects] = useState([]);
    const [newProjectID, setNewProjectID] = useState(false);
    const [projectName, setNewProjectName] = useState('');
    const [extProjects, setExtProjects] = useState(false);
    const [loading, setLoadingStatus] = useState(false);

    // const getExternalProjects = fire.functions().httpsCallable('getExternalProjects');
    const getReblaProjects = fire.functions().httpsCallable('getReblaProjects');
    const getReblaProjectData = fire.functions().httpsCallable('getReblaProjectData');

    console.log('all projects: ', projects);

    useEffect(()=>{
        getAllusers(updateUsers)
        getAllProjects(updateProjects)
    },[user, !users, (projects ? projects.length : '')])

    useEffect(()=>{
        // getReblaProjects()
        //     .then((response) => {
        //         console.log('response from admin', response.data);
        //         // const projectIDs = response.data;
        //         // setExtProjects(projectIDs)
        //     })
        //     .catch(err => console.error(err))
    },[])

    function handleLogin(usr) {
        getUser(usr.uid, updateUser);

    }

    function handleLogout(usr) {
        console.log('log out');
        updateUser(false);
    }

    function handlePermissionChange(_id, val){
        const userID = _id.split('-_-')[0];
        const projectID = _id.split('-_-')[1];
        console.log('userID', userID);
        console.log('projectID', projectID);

        fire.database().ref().child('users').child(userID).child('permissions').child(projectID).set(val);
    }  
    
    async function createNewProject(){
        // const newProjectID = id ? getRandomId();
        if(!projectName) {
            alert("Du måste fylla i ett projektnamn");
            return false;
        }
        const projectBase = {
            'project': {
                'images': {
                    'image1': "https://firebasestorage.googleapis.com/v0/b/test-1c6eb.appspot.com/o/images%2Fhouses2.jpg?alt=media&token=83a52876-c470-4e70-b3d4-33357499f740"
                },
                'title': await projectName
            }
        }

        console.log('projectName', projectName);

        if (newProjectID){
            console.log('save');
            const uniqueProjectID = `${PROJECT_ID_START_STRING}${newProjectID}`;
            if(projects && projects[uniqueProjectID]){
                alert('Detta projekt är redan använt');
                return false;
            }
            fire.database().ref().child('projects').child(uniqueProjectID).set(projectBase);
        }
        else {
            fire.database().ref().child('projects').push(projectBase);
        }

        setTimeout(function(){
            setNewProjectID(false);
            setNewProjectName('');
        },1500)

    }

    function fetchProjectName(){
        setLoadingStatus(true);
        const fetchExtData = getReblaProjectData(newProjectID)
            .then((response) => {
                console.log('response from admin', response.data);

                setNewProjectName(response.data.name);
                setLoadingStatus(false);

                return true;
            })
            .catch(err => {
                console.error(err);
                alert("Hittade inget projekt i api:et med valt ID");
                setTimeout(function () {
                    setNewProjectID(false);
                    setNewProjectName('');
                }, 1500)
                setLoadingStatus(false);

                return false;

            })
        



    }

    function handleInputChange(prop, val){
        setNewProjectName(val);
    }

    function handleIDChange(e, val){
        console.log('id:', val);
        setNewProjectID(val || (e.target ? e.target.value : false));
    }
 
    return (
        <div className="admin">
            <div className="admin container">
                {user.role === "admin" ?
                    <>
                        <Login user={user} onLogin={handleLogin} onLogout={handleLogout} />

                        <div className='users content wrapper'>
                            <h2>Användare</h2>
                            <ul className="list cols-2">
                                {   users && 
                                    Object.keys(users).map((key) => (
                                        <li key={'user-key-' + key} className='list__object user hover' objectid={key}>
                                            <span className="list__object__title">{users[key].userName}</span>
                                            <div className="list__object__info">
                                                <span className="user__role">{users[key].role}</span>
                                                <span className="user__role">{users[key].email}</span>
                                            </div>
                                            {users[key].role !== 'admin' && 
                                            <div className="hover__content">

                                                <div className="flex-row checklist">
                                                    {
                                                        Object.keys(projects).map((id) => (

                                                            <Checkbox key={'checkbox-' + id} id={key + '-_-' + id} checked={users[key].permissions[id]} label={projects[id].project.title} onChange={handlePermissionChange} />

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>

                            <CreateNewUser fire={fire} infoText="Justera vilka behörigheter som användaren ska ha ovan."/>
                           

                        </div>

                        <div className='content wrapper flex-col'>
                            <h2>Skapa ett nytt projekt </h2>
                            <div className="flex-col">
                                <Input large value={projectName||''} label="Ange ett projektnamn" onChange={handleInputChange}/>
                                <span>eller</span>
                                <Input large value={newProjectID || ''} onSave={fetchProjectName} btnText="Hämta namn" placeholder="Ange ett projekt-ID" label="Ange ett projekt-ID (Reblas API)" onChange={handleIDChange} />

                
                            </div>
                            <br />
                            <div className={`btn ${loading ? 'disabled' : ''}`} onClick={createNewProject}>Skapa projekt</div>
                        </div>  

                        <div className='content wrapper flex-col'>
                            <h2>Projektlänkar</h2>
                            <div className="flex-row">
                            {projects &&
                                Object.keys(projects).map((id) => (
                                    <a className="project-link" key={`project-link-${id}`} href={`/admin?id=${id}`}>{projects[id].project.title}</a>
                                ))
                            }
                            </div>

                        </div>                        
                    </>
                :
                <>
                    <Login user={user} onLogin={handleLogin} onLogout={handleLogout} />

                    <p>

                        {user.permissions &&
                        <>
                            <h3>Projektlänkar</h3>

                            {Object.keys(user.permissions).map((projectID)=>(
                                (user.permissions[projectID] && projects[projectID]) && 
                                    <a className="project-link" key={`project-link-${projectID}`} href={`/admin?id=${projectID}`}>Gå till {projects[projectID].project.title}</a>
                                
                            ))}
                        </>
                        }
                    </p>

                </>
                }

            </div>
        </div>
    );
};

export default SuperAdmin;