
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import View from './views/Default';

function App() {

  return (
    <Router>
      <div className='app'>
        <Switch>
          <Route path="/admin" >
              <View admin />
          </Route>
          <Route path="/demo" >
            Detta är en demo
            <div className="demo">
            <div className="bv-iframe-container demo-styles" >
              <link rel='stylesheet' href='http://10.10.42.220:3000/assets/iframe-styles.css' type='text/css' media='all' />
              <iframe src="http://10.10.42.220:3000?id=ekeblad-1" frameborder="0"></iframe>

            </div>
            </div>
          </Route>
          <Route path="/" >
            <div className="view">
              <View />
            </div>
          </Route>
          
        </Switch>
      </div>
    </Router>

  );
}

export default App;
