import { useEffect, useState } from "react";
import { getProject } from '../functions/Queries';
import Loader from '../components/Loader/Loader';
import ImageView from '../components/ImageView/ImageView';
import { useLocation } from "react-router-dom";
import firebase from "../config/firebase";
import Admin from "./Admin/Admin";
import SuperAdmin from "./Admin/SuperAdmin";

// firebase.functions().useEmulator("localhost", 5001);


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const View = (props) => {
    const [loading, setLoading] = useState(true);
    const [project, updateProject] = useState(false);
    const [imageSize, updateImageSize] = useState(false);
    const [activeImg, updateActiveImage] = useState('image1');

    let query = useQuery();
    const projectID = query.get("id");
    const layout = query.get("layout");
    const lang = query.get("lang") || "sv";
    if (layout) {
        document.getElementsByTagName('body')[0].classList.add(layout);
    }
    if (lang) {
        document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    }

    function getProjectData(){
        getProject(projectID, updateProject, setLoading, handleError)
    }

    function handleError(){

        const user = firebase.auth().currentUser;
        // console.log('| | | | | | | | | | | | | | | | | | | | | | | | ');
        // console.log('usr:', user);
        if (!user) {

            firebase.auth().signInAnonymously()
                .then(() => {
                    // Signed in..
                    // console.log('- - - - - - - - - - - -');
                    // console.log('anonym inloggning');
                    // console.log('- - - - - - - - - - - -');


                })
                .catch((error) => {

                    // ...
                });

        }
        else {
            // console.log('inloggad redan');


        }
        // console.log('| | | | | | | | | | | | | | | | | | | | | | | | ');
        getProjectData();



    }

    useEffect(() => {
        if (projectID) {
            getProjectData();                
        }
    }, [])

    function handleHouseClick(houseID, ip_adress){
        const ip = ip_adress.split('.').join('-');

        let newData = {...project.items[houseID]};
        if (newData.users) {
            let clickInfo = newData.users[ip] || { clicks: 0 };
            clickInfo.clicks++;

            newData.users[ip] = clickInfo;
        }
        else {
            let clickInfo = { clicks: 1 };
            newData.users = {};
            newData.users[ip] = clickInfo;
        }

        const handleHouseClick = firebase.functions().httpsCallable('handleHouseClick');

        handleHouseClick({ projectID: projectID, ip: ip, itemData: newData})
            .catch(err => console.error(err));
    }

    return (
        <>
            {loading && projectID ?
                <>
                    <Loader />
                    Hämtar projekt ...
                </>
                :
                <>
                    {!projectID || !project ?
                        <>
                            {props.admin ? 
                                <SuperAdmin />
                            :   
                                <>
                                Hoppsan, du behöver ange en korrekt url.
                                </>
                            }
                        </>
                        :
                        <>
                            {props.admin ? 
                                <Admin imageSize={imageSize} data={project} onLogin={getProjectData} activeImg={activeImg} projectID={projectID} updateActiveImg={updateActiveImage}>
                                    <ImageView getProjectData={getProjectData} setImageSize={updateImageSize} projectID={projectID} admin={props.admin} data={project} activeImg={activeImg} updateActiveImage={updateActiveImage} />
                                </Admin>
                            :
                                <>
                                <ImageView getProjectData={getProjectData} onHouseClick={handleHouseClick} projectID={projectID} admin={props.admin} data={project} activeImg={activeImg} updateActiveImage={updateActiveImage} />
                                {props.children}
                                <div className={(loading && projectID) ? 'loading' : 'loading loaded'}></div>
                                </>
                            }

                        </>

                    }
                </>
            }
        </>

    )
}

export default View;