import React, { Component } from 'react';
import firebase from '../../config/firebase';
import { StringResolver } from '../../functions/StringResolver';
import './ContactForm.scss';

// {
//   "zip": "string",
//   "phone": "string",
//   "projectName": "string",
//   "fullName": "string",
//   "propertyNumbers": [
//     "string"
//     ],
//   "email": "string",
//   "birthYear": "stri",
//   "propertyNumber": "string",
//   "organizationNumber": "string",
//   "organizationName": "string",
//   "rooms": [
//     0
//     ]
// }

class ContactFormRebla extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);

    this.state = {
      name:"",
      email: "",
      phone: "",
      birth_year: "",
      postal_code: "",
      statusOk:false,
      loading:false,
      errorMessage: false
    }
  }

  handleFormChange(e){
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit(e){
    e.preventDefault();
    console.log('submitting', JSON.stringify(this.state));
    this.setState({
      loading:true,
      errorMessage: false
    });
    
    this.submitForm();

  }

  async submitForm(){
    const dest = this.props.email;
    const { name, email, phone, birth_year, postal_code } = this.state;
    const objectName = this.props.objectName;
    const projectName = this.props.projectName;

    const details = {
      fullName : name,
      email : email,
      phone : phone,
      birthYear : birth_year,
      zip : postal_code,
      propertyNumber : objectName,
      projectName : projectName
    }


      // firebase.functions().useEmulator("localhost", 5001);
      const submitForm = firebase.functions().httpsCallable('submitForm');

      submitForm(details)
        .then((result) => {
          console.log('then 1', result);
          this.setState({
            statusOk: true,
            loading: false
          })
        })
        .catch((err) => {
          console.error(err)
          this.setState({
            statusOk: false,
            loading: false,
            errorMessage: true
          })
        });
  }

  render() {

    return (
      <form onSubmit={this.handleSubmit} className={this.state.statusOk ? "contact-form form-submitted" : "contact-form " }>
      <h3>{StringResolver("form_title")}</h3>

        <div className="input-required"><input name="name" type="name" onChange={this.handleFormChange} value={this.state.name} placeholder={StringResolver("name")} required /></div>
        <div className="input-required"><input name="phone" type="tel" onChange={this.handleFormChange} value={this.state.phone} placeholder={StringResolver("phone")} required /></div>
        <div className="input-required"><input name="email" type="email" onChange={this.handleFormChange} value={this.state.email} placeholder={StringResolver("email")} required /></div>
        <input name="birth_year" type="text" maxLength="4" minLength="4" onChange={this.handleFormChange} value={this.state.birth_year} placeholder={StringResolver("birth_year")} />
        <input name="postal_code" type="text" onChange={this.handleFormChange} value={this.state.postal_code} placeholder={StringResolver("postal_code")} />
        <button className={this.state.loading ? "waiting-response" : ""} type="submit" value="Skicka">{StringResolver("send")}</button>
        
        {this.state.errorMessage &&
          <p className="error-message">
            Vi kunde inte skicka formuläret, prova igen. 
            {this.props.email &&
              <>
              <br/>
               Fungerar det fortfarande inte så kan du skicka din intresseanmälan till <a href={`mailto:${this.props.email}`}>{this.props.email}</a>
              </>
            }
          </p>
        }
      </form>
    );
  }
}

export default ContactFormRebla;
