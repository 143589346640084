import React, { Component } from 'react';

import './ImageSlider.scss';

class ImageSlider extends Component {

  constructor(props){
    super(props);

    this.changeSlide = this.changeSlide.bind(this);

    this.state = ({
      style : {
        transform: 'translateX(0)'
      }
    })

    this.images = [
      {
        title: "titel",
        src: "https://cedar-architect.com/app/uploads/2017/11/image_1737714-1.jpg"
      },
      {
        title: "titel 2",
        src: "http://placehold.it/500x250"
      },
      {
        title: "titel 3",
        src: "https://cedar-architect.com/app/uploads/2017/11/image_1737714-1.jpg"
      }
    ]
  }

  changeSlide(e){
    const target = e.target;

    const navId = target.getAttribute('htmlnavto');

    // let _x = this.sliders[0].scrollLeft + 500;
    let _x = this.sliders[0].offsetWidth * (navId);

    console.log('change x: ', _x);
    console.log('change xnavId: ', navId);


    // let img = this.sliders[0].getElementsByTagName('img');

    this.setState({
      style : {
        transform: 'translateX(-' + _x + 'px)'
      }
    })
  }

  componentDidMount(prevProps, prevState){
    this.sliders = document.getElementsByClassName('slider-images-wrapper');
  }

  render() {

    return (
      <div className="image-slider">
        <div className="slider-images-wrapper">
        {
          this.images.map((image, key)=>
          <img alt={"slide-"+ key} key={key.toString()} htmlnavid={key} style={this.state.style} src={image.src} />
          )
        }
        </div>
        <nav className="slider-images-nav">
        {
          this.images.map((image, key)=>
            <div key={key.toString()} htmlnavto={key} onClick={ this.changeSlide }></div>
          )
        }
        </nav>
      </div>
    );
  }
}

export default ImageSlider;
