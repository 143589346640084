import firebase from 'firebase';
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";


import firebaseConfig from './fbConfig';

var fire = firebase.initializeApp(firebaseConfig.config);

export default fire;